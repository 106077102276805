import React from 'react';

function MusicFormat(props) {

    const buttons = [
        { name: "Youtube", icon: "fa fa-youtube", url: props.youtubeUrl },
        { name: "Apple Music", icon: "fa fa-apple", url: props.appleMusicUrl },
        { name: "Spotify Music", icon: "fa fa-spotify", url: props.spotifyUrl },
        { name: "Soundcloud", icon: "fa fa-soundcloud", url: props.soundCloudUrl },
    ];

    return (
        <div className='music-format'>
            <div className='inner'>
                <div><img className='music-format-img' src={props.imgUrl} alt='artwork' /></div>
                <div className='row v-center h-center mt-16'>
                    {
                        buttons.map((each, i) => {
                            return (
                                <div className='btn-container' key={i}>
                                    <a href={each.url} target="_blank" rel="noreferrer">
                                        <button className='music-format-btn'><i className={`${each.icon}`}></i></button>
                                    </a>
                                </div>
                            );
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default MusicFormat;