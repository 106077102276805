import React from "react";

function ProductFormat(props) {
    return (
        <div className="product-format">
            <div style={{ height: 400, width: "100%", overflow: "hidden" }}>
                <div className="product-image" style={{ backgroundImage: `url(${props.imgUrl})` }}></div>
            </div>
            <div className="mt-16 center" style={{ fontWeight: "bold" }}>{props.productName}</div>
            <div className="mt-8 center"><b>₦</b> {props.price}</div>
        </div>
    )
}

export default ProductFormat;