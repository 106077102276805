import React, { useState, useEffect } from "react";
import { getProducts } from "./reusables/GetProducts";
import Loader from "./reusables/Loader";
import ProductFormat from "./reusables/ProductFormat";

function Shop() {

    let [navHeight, setNavHeight] = useState(0);
    let [content, setContent] = useState(<Loader />);

    useEffect(() => {
        const nav = document.getElementsByClassName("nav");
        setNavHeight(nav[0].clientHeight);

        getProducts((res, err) => {
            if (err) return console.log(err.message);
            if (res.status === true) {
                setContent(
                    res.msg.map((each, i) => {
                        return (
                            <ProductFormat
                                key={i}
                                productName={each.name}
                                imgUrl={each.image_url}
                                price={each.amount}
                            />
                        );
                    })
                );
            } else {
                setContent(
                    <h2 className="bold center">{res.msg}</h2>
                );
            }
        });
    }, []);

    return (
        <div>
            <div style={{
                marginTop: navHeight + 8
            }} className="boxed">
                {/* <h1>Shop</h1> */}
                <div className="mt-16 boxed">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Shop;