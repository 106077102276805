import React, { useState, useEffect } from 'react';
import { GetPosts } from './reusables/GetPosts';
import Loader from "../client/reusables/Loader";
import BlogPostFormat from './reusables/BlogPostFormat';

function Blog() {

    // let [isLoading, setIsLoading] = useState(true);
    let [navHeight, setNavHeight] = useState(0);
    let [content, setContent] = useState(<Loader />);




    useEffect(() => {
        const nav = document.getElementsByClassName("nav");
        setNavHeight(nav[0].clientHeight);

        GetPosts((res, err) => {
            if (err) {
                return console.log("Error", err.message);
            }
            if (res.status === true) {
                const { msg } = res;
                console.log(msg)
                setContent(
                    msg.map((each, i) => {
                        return (
                            <a key={i} target="_blank" rel="noreferrer" href={`${each.post_url}`}>
                                <BlogPostFormat

                                    imgUrl={each.image_url}
                                    postTitle={each.title}
                                    postDate={each.created_at}
                                />
                            </a>
                        )
                    })
                );
            }
        });
    }, []);


    return (
        <div className='blog-page'>
            <div style={{
                marginTop: navHeight + 8,
                height: "100%"
            }} className="boxed">
                <div className='boxed'>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Blog;