import { ENDPOINTS } from "../../utils/Endpoints";
import { API_URL } from "../../utils/variables";
import { MakeGetRequest } from "./MakeRequest";

export function getProducts(callback) {
    const url = `${API_URL}${ENDPOINTS.getProducts}`;
    MakeGetRequest(url, (res, err) => {
        if (res) callback(res, undefined);
        if (err) callback(undefined, err);
    })
}