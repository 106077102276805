import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../client/reusables/Loader";
import { GetPosts } from "../client/reusables/GetPosts";

function Blog() {

    let [content, setContent] = useState(<div className="row v-center h-center boxed"><Loader /></div>);

    useEffect(() => {
        GetPosts((res, err) => {
            if (err) return console.log("Error", err.message);
            if (res.status === true) {
                const { msg } = res;
                setContent(
                    <table>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                msg.map((each, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><img src={each.image_url} alt="img" width={100} height={100} style={{ objectFit: "contain" }} /></td>
                                            <td>{each.title}</td>
                                            <td>{each.post_url}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                );
            } else {
                setContent(res.msg);
            }

        });
    }, []);

    return (
        <div>
            <div className="dashboard-page boxed">
                <div className="row v-center">
                    <div className="flex-1">
                        <h2>Posts</h2>
                    </div>
                    <div className="flex-1 flex-end flex">
                        <Link to={"/admin/dashboard/add-post"}><button className="primary-btn">Add New</button></Link>
                    </div>
                </div>

                <div className="mt-16">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Blog;