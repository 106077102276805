import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../client/reusables/Loader";
import { MakePostRequest } from "../client/reusables/MakeRequest";
import { ErrorModal } from "../client/reusables/Modals";
import Popup from "../client/reusables/Popup";
import { ENDPOINTS } from "../utils/Endpoints";

function Login() {

    let [showPopup, setShowPopup] = useState(false);
    let [popupContent, setPopupContent] = useState(<Loader />);
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    const navigate = useNavigate();

    const submit = (e) => {
        e.preventDefault();
        setShowPopup(true);
        if (email === "" || password === "") {
            setPopupContent(
                <ErrorModal
                    title="Unable to log in"
                    desc="Please fill in all required fields"
                    btnAction={() => {
                        setShowPopup(false);
                        setPopupContent(<Loader />);
                    }}
                />
            )
            setShowPopup(false);
        } else {
            let form = new FormData();
            form.append("email", email);
            form.append("password", password);

            const url = ENDPOINTS.adminLogin;
            MakePostRequest(form, url, (res, err) => {
                if (err) {
                    setPopupContent(
                        <ErrorModal
                            title="Unable to log in"
                            desc={err.message}
                            btnAction={() => {
                                setShowPopup(false);
                                setPopupContent(<Loader />);
                            }}
                        />
                    );
                }
                if (res.status === true) {
                    navigate("/admin/dashboard/");
                } else {
                    setPopupContent(
                        <ErrorModal
                            title="Unable to log in"
                            desc={res.msg}
                            btnAction={() => {
                                setShowPopup(false);
                                setPopupContent(<Loader />);
                            }}
                        />
                    );

                }
            });
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div style={{ width: (window.innerWidth > 992) ? "35%" : "90%" }}>
                <div className="fs-24 fw-b">Welcome back<br />Log in</div>
                <form onSubmit={submit}>
                    <div className="mt-16">
                        <input className="input" placeholder="Email Address" type="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mt-16">
                        <input className="input" placeholder="Password" type="password" suggested="current-password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="mt-16">
                        <button className="submit-btn">Log In</button>
                    </div>
                </form>
            </div>
            {showPopup && <Popup content={popupContent} />}
        </div>
    );
}

export default Login;