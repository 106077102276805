import React, { useState, useEffect } from 'react';
import MusicFormat from './reusables/MusicFormat';
import { GetSongs } from "./reusables/GetSongs";

function Music() {

    let [songs, setSongs] = useState("");
    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    window.addEventListener("resize", () => {
        setIsMobile(window.innerWidth < 992);
    });

    useEffect(() => {
        GetSongs((res, err) => {
            if (err) return console.log("Error", err.message);
            if (res.status === true) {
                const { msg } = res;
                setSongs(msg.map((each, i) => {
                    return (
                        <MusicFormat
                            key={i}
                            imgUrl={each.artwork}
                            youtubeUrl={each.youtube}
                            appleMusicUrl={each.apple_music}
                            spotifyUrl={each.spotify}
                            soundCloudUrl={each.soundcloud}
                        />
                    )
                }))
            } else {
                setSongs(res.msg);
            }
        })
    }, []);

    if (Array.isArray(songs) && songs.length > 0) {
        return (
            <div className='boxed'>
                <div className="alt-txt center boxed" style={{ fontSize: isMobile ? "2em" : "3em" }}>Out Now!!</div>
                <div className='boxed'>
                    {songs}
                </div>
            </div>
        );
    }
}

export default Music;