export const ENDPOINTS = {
    addPost: "/add-post.php",
    getPost: "/get-post.php",
    addMusic: "/add-music.php",
    getSongs: "/get-music.php",
    getProducts: "/get-products.php",

    //ADMIN.
    adminLogin: "/admin/login.php",
    adminAddProducts: "/admin/add-products.php",
}