import React from "react";
import { WEB_URL } from "../utils/variables";
import Socials from "./reusables/Socials";

function Contact() {

    const details = [
        { icon: "fa fa-envelope", value: "doncrucifixto@gmail.com" },
        { icon: "fa fa-phone", value: "+1 (402) 301-5382" },
        // { icon: "fa fa-clock", value: "9:00 am - 5:00 pm" }
    ]

    return (
        <div className="boxed">
            <div className="fs-32 alt-txt center gradient-txt">Contact Us</div>
            <div className="center fs-24 mt-16">Our team will be in touch with you soon</div>
            <div className="flex v-center mt-16">
                <div className="flex-1 center boxed">
                    <i className="fa fa-phone alt-txt fs-32"></i>
                    <div className="grey-txt fw-b mt-8">Call us today</div>
                    <div className="mt-16">+1 (402) 301-5382</div>
                </div>
                <div className="flex-1 center boxed">
                    <i className="fa fa-envelope alt-txt fs-32"></i>
                    <div className="grey-txt fw-b mt-8">Send us an email</div>
                    <div className="mt-16"><a href="mailto:doncrucifixto@gmail.com">doncrucifixto@gmail.com</a></div>
                </div>
                <div className="flex-1 center boxed">
                    <i className="fa fa-map-marker alt-txt fs-32"></i>
                    <div className="grey-txt fw-b mt-8">Visit our HQ</div>
                    <div className="mt-16">
                        <div>Lagos, Nigeria</div>
                        <div className="mt-8">Omaha, USA</div>
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="flex-1 mt-16">
                    <div className="boxed">
                        {/* <div className="fs-24 alt-txt fw-700">285A Ajose Adeogun,</div> */}
                        <div className="fs-24 alt-txt fw-700 mt-8">Lagos, Nigeria</div>
                        <div className="fs-24 alt-txt fw-700 mt-8">Omaha, USA</div>
                        {/* <div className="mt-8 fw-b">Postcode: 000000.</div> */}
                        <div className="mt-16">
                            {
                                details.map((each, i) => {
                                    return (
                                        <div className="row v-center mt-8" key={i}>
                                            <div style={{ width: (window.innerWidth > 992 && "5%") || "10%" }}>
                                                <i className={`${each.icon} alt-txt`}></i>
                                            </div>
                                            <div className="grey-txt fw-b">{each.value}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <Socials />
                    </div>
                </div>
                <div className="flex-1">
                    <div className="contact-form-div boxed br-8 mt-16">
                        <div className="alt-txt fs-24 fw-700 boxed">Send us a message</div>
                        <form action="https://formsubmit.co/doncrucifixto@gmail.com" method="POST">
                            <div className="flex">
                                <div className="flex-1 boxed">
                                    <input type="text" placeholder="Full name" className="input" name="full-name" required />
                                </div>
                                <div className="flex-1 boxed">
                                    <input type="email" placeholder="Email address" className="input" name="email" required />
                                </div>
                                <div>
                                    <input type="hidden" name="_captcha" value="false" />
                                    <input type="hidden" name="_next" value={`${WEB_URL}/thanks`} />
                                </div>
                            </div>
                            <div className="boxed">
                                <textarea className="input" placeholder="Your Message" rows="6" name="message" required></textarea>
                            </div>
                            <div className="boxed">
                                <button className="submit-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;