
import React from "react";
import { Route, Routes } from "react-router-dom";
import Client from "./components/client/index";
import Thanks from "./components/client/Thanks";
import Admin from "./components/admin/index";

function App() {
	return (
		<Routes>
			<Route exact path={"/*"} element={<Client />} />
			<Route exact path={"/thanks"} element={<Thanks />} />
			<Route exact path={"/admin/*"} element={<Admin />} />
		</Routes>
	)
}

export default App;