import React, { useState, useEffect } from "react";
import Loader from "../client/reusables/Loader";
import { useNavigate } from "react-router-dom";
import { MakePostRequest } from "../client/reusables/MakeRequest";
import { ErrorModal, SuccessModal } from "../client/reusables/Modals";
import Popup from "../client/reusables/Popup";
import { ENDPOINTS } from "../utils/Endpoints";

function AddProducts() {

    let [productName, setProductName] = useState("");
    let [productPrice, setProductPrice] = useState("");
    let [productImage, setProductImage] = useState("");
    let [showPopup, setShowPopup] = useState(false);
    let [popupContent, setPopupContent] = useState(<Loader />);

    const navigate = useNavigate();

    function showError(title, desc) {
        setPopupContent(<ErrorModal
            title={title}
            desc={desc}
            btnAction={() => {
                setShowPopup(false);
                setPopupContent(<Loader />);
            }}
        />);
    }

    //open file mannager.
    const addImage = (e) => {
        e.preventDefault();
        const productFile = document.getElementById("product-file");
        productFile.click();
    }

    //when an image is selected from file manager.
    const selectImage = (e) => {
        let productImage = document.getElementById("product-image");
        setProductImage(e.target.files[0]);
        productImage.src = URL.createObjectURL(e.target.files[0]);
    }

    //submit form.
    const submit = (e) => {
        e.preventDefault();
        setShowPopup(true);
        if (productName === "" || productPrice === "" || productImage === "") {
            return setPopupContent(
                <ErrorModal
                    title="Unable to add product"
                    desc="Please fill in all required fields"
                    btnAction={() => {
                        setShowPopup(false);
                        setPopupContent(<Loader />);
                    }}
                />
            );
        }

        const form = new FormData();
        form.append("file_to_upload", productImage);
        form.append("name", productName);
        form.append("price", productPrice);

        const url = ENDPOINTS.adminAddProducts;

        MakePostRequest(form, url, (res, err) => {
            if (err) return showError("Unable to add product", err.message);
            if (res.status === true) {
                setPopupContent(
                    <SuccessModal
                        title="Success"
                        desc={res.msg}
                        btnAction={() => {
                            setShowPopup(false);
                            setPopupContent(<Loader />);
                            navigate("/admin/dashboard/products");
                        }}
                    />
                )
            } else {
                showError("Unable to add product", res.msg);
            }
        })
    }

    return (
        <div className="boxed">
            <h2>Add Products</h2>
            <form onSubmit={submit}>
                <div className="flex">
                    <div className="flex-2">
                        <div className="boxed">
                            <label>Product Name</label>
                            <input className='input mt-8' type="text" placeholder="Enter product name" onChange={(e) => setProductName(e.target.value)} />
                        </div>
                        <div className="boxed">
                            <label>Product Price</label>
                            <input className='input mt-8' type="number" placeholder="Enter product price" onChange={(e) => setProductPrice(e.target.value)} />
                        </div>
                        <div className="boxed">
                            <label>Add Product Image</label>
                            <input type="file" accept="image/*" id="product-file" onChange={selectImage} hidden />
                            <div className="mt-8">
                                <img style={{
                                    width: 200,
                                    height: 200,
                                    border: "1px solid #f0f0f0",
                                    objectFit: "contain"

                                }} id="product-image" />
                            </div>
                            <button className="primary-btn" onClick={addImage}>Add Image</button>
                        </div>
                        <div className="boxed">
                            <button className="primary-btn">Publish</button>
                        </div>
                    </div>
                    <div className="flex-1"></div>
                </div>
            </form>
            {showPopup && <Popup content={popupContent} />}
        </div >
    )
}

export default AddProducts;