import React, { useState } from "react";
import { ButtonWithArrow } from "../utils/Buttons";
import EventMedia from "../utils/EventMedia";
import { UPLOADS_PATH } from "../utils/variables";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Music from "./Music";

function Home() {

    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    let [activeService, setActiveService] = useState("record label");
    let [currentIndex, setCurrentIndex] = useState(0);
    let [showGallery, setShowGallery] = useState(false);

    const galleryImages = [
        `${UPLOADS_PATH}/event1.jpg`,
        `${UPLOADS_PATH}/event2.jpg`,
        `${UPLOADS_PATH}/event3.jpg`,
        `${UPLOADS_PATH}/event4.jpg`,
        `${UPLOADS_PATH}/event5.jpg`,
        `${UPLOADS_PATH}/event6.jpg`,
        `${UPLOADS_PATH}/event7.jpg`,
        `${UPLOADS_PATH}/event8.jpg`,
        `${UPLOADS_PATH}/event9.jpg`,
        `${UPLOADS_PATH}/event10.jpg`,
        `${UPLOADS_PATH}/event11.jpg`,
        `${UPLOADS_PATH}/event12.jpg`,
        `${UPLOADS_PATH}/event13.jpg`,
        `${UPLOADS_PATH}/event14.jpg`,
        `${UPLOADS_PATH}/event15.jpg`,
        `${UPLOADS_PATH}/event16.jpg`,
        `${UPLOADS_PATH}/event17.jpg`,
        `${UPLOADS_PATH}/event18.jpg`,
        `${UPLOADS_PATH}/event19.jpg`,
        `${UPLOADS_PATH}/event20.jpg`,
    ];

    window.addEventListener("resize", () => {
        setIsMobile(window.innerWidth < 992);
    });

    const services = [
        { name: "Record Label", imgUrl: `${UPLOADS_PATH}/music.png`, size: 25 },
        { name: "Artiste Management", imgUrl: `${UPLOADS_PATH}/star.png`, size: 25 },
        { name: "Distribution", imgUrl: `${UPLOADS_PATH}/cam.png`, size: 25 },
        { name: "Publishing Management", imgUrl: `${UPLOADS_PATH}/mic.png`, size: 25 },
    ];

    const RecordLabel = () => {
        return (
            <div className="flex v-center">
                {isMobile && <div className='fs-24 fw-700 boxed grey-txt'>Record Label</div>}
                <div className="flex-1 boxed">
                    {!isMobile && <div className='fs-32 fw-700 boxed grey-txt'>Record Label</div>}
                    <div className="boxed">
                        We are a full-fledged recording company responsible for the
                        production, distribution, sales, and publishing of all our
                        talents’ musical content. As a brand committed to the
                        overall affairs of artistes signed under our label, it is our duty
                        to manage the image of these talents by building a mutual
                        relationship which has been finessed into making them
                        celebrated personalities and performers in the Nigerian
                        entertainment industry.
                    </div>
                </div>
                <div className="flex-1 center">
                    <img src={`${UPLOADS_PATH}/logo2.png`} width={"60%"} height={"auto"} alt="img" />
                </div>
            </div>
        )
    }

    const ArtisteManagement = () => {
        return (
            <div className="flex v-center">
                {isMobile && <div className='fs-24 fw-700 boxed grey-txt'>Artiste Management</div>}
                <div className="flex-1 boxed center">
                    <img className="br-8" src={`${UPLOADS_PATH}/artiste.png`} width={"50%"} height={"auto"} alt="img" />
                </div>
                <div className="flex-1">
                    {!isMobile && <div className='fs-32 fw-700 boxed grey-txt'>Artiste Management</div>}
                    <div className="boxed">
                        We are in the business of modelling the career of artistes in
                        the entertainment industry. Our commitment to this course
                        entails representing and providing support to them in all
                        spheres of function. We stand on guard when making
                        decisions that affect their career, we oversee their progress
                        and conduct their performance at every level of growth.

                    </div>
                </div>
            </div>
        );
    }

    const Distribution = () => {
        return (
            <div className="flex v-center">
                {isMobile && <div className="fs-24 fw-700 boxed grey-txt">Distribution</div>}
                <div className="flex-1 boxed">
                    {!isMobile && <div className="fs-32 fw-700 boxed grey-txt">Distribution</div>}
                    <div className="boxed">
                        In a fast evolving and highly saturated institution like the
                        Nigerian music industry, we exert so much energy in
                        distribution of our talents’ contents through the most
                        efficient musical stores in the world. We work with full force
                        to keep track of sales and earnings off an artiste’s content
                        and we apply the best digital measures in the strategic
                        distribution of these contents. The digital arm of our
                        company takes care of dispersing contents on different
                        social media and music platforms (YouTube, AppleMusic,
                        Triller, Spotify) where numbers will convert into money.
                    </div>
                </div>
                <div className="flex-1 center boxed">
                    <img className="br-8" src={`${UPLOADS_PATH}/artiste2.jpg`} width={"50%"} height={"auto"} alt="img" />
                </div>
            </div>
        );
    }

    const PublishingManagement = () => {
        return (
            <div className="flex v-center">
                {isMobile && <div className="fs-24 fw-700 boxed grey-txt">Publishing Management</div>}
                <div className="flex-1 center boxed">
                    <img className="br-16" src={`${UPLOADS_PATH}/dcg.jpg`} width={"60%"} height={"auto"} alt="img" />
                </div>
                <div className="flex-1 boxed">
                    {!isMobile && <div className="fs-32 fw-700 boxed grey-txt">Publishing Management</div>}
                    <div className="boxed">
                        As a team of profit-oriented experts, we represent our
                        artistes in securing their commissions for music and we
                        invest a great deal in the promotion of their contents.
                    </div>
                </div>

            </div>
        );
    }

    return (
        <div>
            <div className="home-hero" id="home">
                <div className="hero-overlay">
                    <div className="hero-txt" style={{
                        position: "absolute",
                        bottom: "20%",
                        right: "10%",
                        // display: isMobile ? "flex" : null,
                        // flexDirection: "column",
                        // alignItems: "center",
                        // justifyContent: "center"
                    }}>
                        <div className="custom-font gradient-txt" style={{
                            fontSize: !isMobile ? "8em" : "3.5em",
                        }}>DON CRUCIFIXTO</div>
                        <div className="gradient-txt" style={{
                            fontSize: !isMobile ? "2.5em" : "1.2em",
                            fontWeight: "bold"
                        }}>Entertainment</div>
                    </div>
                </div>
            </div>
            <div className="event-gallery boxed">
                <div className={isMobile ? "flex" : "flex v-center"}>
                    <div className="boxed">PRIVILEGED (Movie): Omaha Private Viewing Event Gallery</div>
                    <div className="boxed">
                        <ButtonWithArrow
                            btnText="View More"
                            clicked={() => setShowGallery(true)}
                        />
                    </div>
                </div>
                <div className="boxed events-img-display">
                    <EventMedia imgUrl={`${UPLOADS_PATH}/event1.jpg`} />
                    <EventMedia imgUrl={`${UPLOADS_PATH}/event2.jpg`} />
                    <EventMedia imgUrl={`${UPLOADS_PATH}/event3.jpg`} />
                    <EventMedia imgUrl={`${UPLOADS_PATH}/event4.jpg`} />
                    <EventMedia imgUrl={`${UPLOADS_PATH}/event5.jpg`} />
                </div>
            </div>
            <div id="about" className="boxed">
                <div className="alt-txt center boxed gradient-txt" style={{ fontSize: isMobile ? "2em" : "3em" }}>About Us</div>
                <div className="center">
                    <img src={`${UPLOADS_PATH}/logo2.png`} alt="img" width={isMobile ? 200 : 300} height="auto" />
                </div>
                <div className="flex">
                    <div className="flex-1"></div>
                    <div className="flex-3">
                        <div className="fw-b center fs-16 boxed">
                            Don Crucifixto Entertainment is a household media company sited in the United States for the
                            purose of talent recruitment, management, promotion, film production, and photography.
                        </div>
                    </div>
                    <div className="flex-1"></div>
                </div>
                <div className="flex v-center">
                    <div className="flex-1 center boxed">
                        <img src={`${UPLOADS_PATH}/don-about.jpeg`} width={"80%"} height={"auto"} alt="img" className="br-16" />
                    </div>
                    <div className="flex-2 boxed fs-16">
                        <p>
                            We are an American-based Nigerian entertainment outfit founded by Tumininu Oluyole, a successful show businessman and entrepreneur who officially launched the brand in August 2020. Prior to then, DonCrucifixto has been identified as a brand that is passionate about adding value to the public. We are a record company hugely committed to
                            contributing to the growth of Nigeria and other African countries. Despite being domiciled in the United States, the digital marketing sector of our company has long been outsourced
                            to Nigeria where all operations fully take a course while a lot of less privileged kids in the West African region especially Nigeria take advantage of our non-governmental organization. Our
                            offices in London and Lagos play huge roles in carrying out these operations.
                        </p>
                        <br />
                        <p>
                            Don Crucifixto is currently comprised of performing artistes signed to the label, music
                            executives, digital media gurus, and a host of cinematographers who work with hands joined
                            to execute the finest productions. An integral part of our film production unit also produces
                            movies for Netflix.
                        </p>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-1"></div>
                    <div className="flex-2 boxed" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="alt-txt center fs-24">Mission Statement</div>
                        <div className="mt-8">
                            The mission of Don Crucifixto Entertainment is to create and deliver high-quality entertainment experiences that captivate and inspire audiences around the world.
                            We aim to entertain and uplift individuals through our innovative and diverse range of content, including films, television series, music, and live performances. Our mission is driven by a commitment to artistic excellence, cultural diversity, and the power of storytelling to connect people on a global scale.
                        </div>
                        <div className="mt-16">
                            <div className="alt-txt center fs-24">Vision Statement</div>
                            <div className="mt-8">
                                Our vision is to become a globally recognized leader in the entertainment industry, known for our exceptional creativity, groundbreaking storytelling, and commitment to pushing boundaries.
                                We strive to be at the forefront of entertainment innovation, consistently delivering unique and compelling experiences that leave a lasting impact on our audience.
                                By embracing diversity and nurturing talent, we aim to foster an inclusive and collaborative environment that encourages creativity and pushes the boundaries of what is possible in the world of entertainment.
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="alt-txt center fs-24">Values Statement</div>
                            <div className="mt-8">
                                <p>
                                    At Don Crucifixto Entertainment, our values serve as the foundation for everything we do. We believe in upholding the highest standards of integrity, creativity, and excellence in all aspects of our work.
                                    Our core beliefs and principles shape our interactions with our clients, partners, and team members.
                                </p>
                                <p className="mt-8">
                                    <b><i>Integrity :</i></b> We believe in conducting our business with honesty, transparency, and ethical practices. We strive to build trust and maintain the highest level of integrity in all our relationships. We are committed to doing what is right, even when faced with difficult decisions.
                                </p>
                                <p className="mt-8">
                                    <b><i>Creativity :</i></b> We embrace innovation, originality, and imagination in all our endeavors. We believe in pushing boundaries, exploring new ideas, and challenging the status quo. We encourage creativity in our team members and foster an environment that nurtures their unique talents and abilities.
                                </p>
                                <p className="mt-8">
                                    <b><i>Collaboration :</i></b> We believe in the power of teamwork and collaboration. We foster a culture of open communication, respect, and support among our team members. We value diverse perspectives and actively encourage collaboration to achieve the best possible outcomes for our clients and partners.
                                </p>
                                <p className="mt-8">
                                    <b><i>Innovation :</i></b> We embrace change and constantly seek new ways to improve and evolve. We encourage our team members to think outside the box, explore emerging technologies, and embrace innovation in all aspects of our work. We stay ahead of industry trends and strive to be at the forefront of innovation in the entertainment industry.
                                </p>
                                <p className="mt-8">
                                    <b><i>Customer Focus :</i></b> We put our clients at the center of everything we do. We listen to their needs, understand their goals, and strive to exceed their expectations. We are committed to delivering exceptional customer experiences and building long-lasting relationships based on trust, reliability, and mutual success.
                                </p>
                                <p className="mt-8">
                                    <b><i>Social Responsibility :</i></b> We believe in giving back to the community and being responsible corporate citizens. We actively engage in initiatives that promote social, environmental, and economic sustainability. We are committed to making a positive impact on society and actively seek opportunities to contribute to the greater good.
                                </p>
                                <p className="mt-8">
                                    By adhering to these core values, Don Crucifixto Entertainment aims to be a leader in the entertainment industry, providing unparalleled experiences and fostering a culture of creativity, integrity, and excellence.
                                </p>
                            </div>
                        </div>
                        <div className="mt-16">
                            <div className="alt-txt center fs-24">History and background</div>
                            <div className="mt-8">
                                <p>Don Crucifixto Entertainment is a company that is passionate about celebrating African talent and filling gaps in the entertainment industry.</p>
                                <p className="mt-8">
                                    Don Crucifixto Entertainment is an entertainment outfit founded by Tumininu Oluyole, also known as Tumi, a Nigerian entertainment entrepreneur based in the United States. Tumi's vision for the company is to showcase the talent and creativity of African artists, while also addressing the existing gaps in the entertainment industry.
                                </p>
                                <p className="mt-8">
                                    Don Crucifixto Entertainment is committed to promoting African artists and providing them with opportunities to showcase their talents on a global scale. The company is tailored towards signing African artistes, emphasizing their unique voices and cultural significance.
                                </p>
                                <p className="mt-8">
                                    Overall, the values of Don Crucifixto Entertainment are centered around celebrating diversity, promoting cultural heritage, and providing a platform for emerging artists. The company seeks to create exceptional and inspiring entertainment experiences that captivate audiences while contributing to the growth and development of the entertainment industry.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1"></div>
                </div>
            </div>
            <div id="services" className="boxed">
                <div className="alt-txt center boxed gradient-txt" style={{ fontSize: isMobile ? "2em" : "3em" }}>Our Services</div>
                {!isMobile &&
                    <div>
                        <div className="flex v-center boxed">
                            {
                                services.map((each, i) => {
                                    return (
                                        <div
                                            className="flex-1 boxed center br-8 pointer"
                                            key={i}
                                            style={{
                                                border: activeService.toLowerCase() === each.name.toLowerCase() ? "1px solid #DCA744" : null,
                                            }}
                                            onClick={() => {
                                                const serviceContent = document.getElementsByClassName("service-content");
                                                if (i === currentIndex) return;
                                                if (i > currentIndex) {
                                                    serviceContent[0].className = "service-content boxed move-right";
                                                }
                                                if (i < currentIndex) {
                                                    serviceContent[0].className = "service-content boxed move-left";
                                                }
                                                setActiveService(each.name.toLowerCase());
                                                setCurrentIndex(i);
                                                setTimeout(() => {
                                                    serviceContent[0].className = "service-content boxed";
                                                    setActiveService(each.name.toLowerCase());
                                                }, 500);
                                            }}
                                        >
                                            <img src={each.imgUrl} width={each.size} height="auto" alt="img" />
                                            <div className="boxed service-title fw-b fs-16 mt-16">{each.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="service-content boxed">
                            {activeService === "record label" && <RecordLabel />}
                            {activeService === "artiste management" && <ArtisteManagement />}
                            {activeService === "distribution" && <Distribution />}
                            {activeService === "publishing management" && <PublishingManagement />}
                        </div>
                    </div>
                }
                {isMobile &&
                    <div>
                        <RecordLabel />
                        <ArtisteManagement />
                        <Distribution />
                        <PublishingManagement />
                    </div>

                }
            </div>
            {/* <div id="artiste">
                <div className="alt-txt center boxed gradient-txt" style={{ fontSize: isMobile ? "2em" : "3em" }}>Artiste</div>
                <div className="mt-16 artiste-div boxed">
                    <div className="ewa-artwork-div boxed">
                        <a target="_blank" href="https://hypeddit.com/dcewa/minimanimo">
                            <img src={`${UPLOADS_PATH}/ewa-new-art.jpg`} width={200} height={"auto"} alt="img" />
                            <div className="mt-16">
                                <div className="alt-txt fs-16 fw-700">Mini Mani Mo</div>
                                <div className="fs-16">2022</div>
                            </div>
                        </a>
                    </div>
                    <div className="ewa-video-div boxed">
                        <div className="alt-txt fs-16 fw-700">Abeg<br />Music Video</div>
                        <iframe src="https://www.youtube.com/embed/15bOeinnjss?autoplay=0&controls=0&showinfo=0&mute=1" title="video" width={250} height={180}></iframe>
                    </div>
                    <div className="ewa-txt alt-txt fs-64 fw-700 boxed">EWA</div>
                </div>
            </div> */}
            <div>
                <Music />
            </div>
            <div className="boxed" id="movie">
                <div className="alt-txt center boxed gradient-txt" style={{ fontSize: isMobile ? "2em" : "3em" }}>Movie</div>
                <div className="mt-16 boxed center">
                    <img src={`${UPLOADS_PATH}/movie-art.jpg`} width={(isMobile ? "100%" : "80%")} height={"auto"} alt="img" className="br-8" />
                </div>
                <div className="mt-16">
                    <div className="alt-txt center fs-24 fw-700">Trailer</div>
                    <div className="flex mt-16">
                        <div className="flex-1"></div>
                        <div className="flex-3">
                            <iframe src="https://www.youtube.com/embed/oKk7fzmRo4Q?autoplay=0&controls=0&showinfo=0&mute=0" title="video" width={"100%"} height={!isMobile ? 400 : 300}></iframe>
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </div>
                <div className="mt-16">
                    <div className="alt-txt center">SYNOPSIS</div>
                    <div className="flex">
                        <div className="flex-1"></div>
                        <div className="flex-2 boxed">
                            <p className="center">The story represents an uncomfortable conversation between the Black American community and the African immigrant community.</p>
                            <div className="mt-16">
                                <div className="alt-txt center">CAST</div>
                                <div className="mt-16 center">
                                    JOSHUA EADY, TRACY OBONNA, TRAVIS CURE,
                                    BAYO ALAWIYE, TUMI OLUYOLE, GINNY CIPOLLA
                                    and BUNMI OLATILEWA
                                </div>
                            </div>
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </div>
            </div>
            <div className="boxed" id="contact">
                <Contact />
            </div>
            {showGallery && <Gallery images={galleryImages} close={() => setShowGallery(false)} />}
        </div>
    )
}

export default Home;