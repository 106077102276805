import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../client/reusables/Loader";
import { GetSongs } from "../client/reusables/GetSongs";

function Music() {

    const navigate = useNavigate();

    let [content, setContent] = useState(<div className="row v-center h-center boxed"><Loader /></div>);

    useEffect(() => {
        GetSongs((res, err) => {
            if (err) return console.log("Error", err.message);
            if (res.status === true) {
                const { msg } = res;
                setContent(
                    <table>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Title</th>
                                <th>Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                msg.map((each, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><img src={each.artwork} alt="img" width={100} height={100} style={{ objectFit: "contain" }} /></td>
                                            <td>{each.youtube}</td>
                                            <td>{each.spotify}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                );
            } else {
                setContent(res.msg);
            }

        });
    }, []);

    return (
        <div>
            <div className="boxed row v-center">
                <div className="flex-1">
                    <h2>Music</h2>
                </div>
                <div className="flex-1 flex flex-end">
                    <button className="primary-btn" onClick={() => navigate("/admin/dashboard/add-music")}>Add new</button>
                </div>
            </div>
            <div className="boxed">
                {content}
            </div>
        </div>
    )
}

export default Music;