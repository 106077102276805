export function SuccessModal(props) {
    return (
        <div className="modal-div boxed">
            <div className="center">
                <i className="fa fa-check alt-txt fs-48"></i>
                <div className="fs-24 fw-700">{props.title}</div>
            </div>
            <div className="mt-16 boxed">
                {props.desc}
            </div>
            <div className="boxed center">
                <button className="primary-btn" onClick={props.btnAction}>Ok, Got it!</button>
            </div>
        </div>
    )
}

export function ErrorModal(props) {
    return (
        <div className="modal-div boxed">
            <div className="center">
                <i className="fa fa-ban fs-48" style={{ color: "red" }}></i>
                <div className="fs-24 fw-700">{props.title}</div>
            </div>
            <div className="mt-16 boxed">
                {props.desc}
            </div>
            <div className="boxed center">
                <button className="primary-btn" onClick={props.btnAction}>Ok, Got it!</button>
            </div>
        </div>
    )
}