import { API_URL } from "../../utils/variables";

export function MakePostRequest(data, url, callback) {
    // let myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data; boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL");

    const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: data,
        // redirect: 'follow'
    }

    fetch(`${API_URL}${url}`, requestOptions)
        .then(response => response.json())
        .then(data => callback(data, undefined))
        .catch(err => callback(undefined, err))
}

export function MakeGetRequest(url, callback) {
    fetch(url)
        .then(response => response.json())
        .then(data => callback(data, undefined))
        .catch(err => callback(undefined, err))
}