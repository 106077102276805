import React, { useState } from "react";
import Loader from "../client/reusables/Loader";
import { MakePostRequest } from "../client/reusables/MakeRequest";
import { ErrorModal, SuccessModal } from "../client/reusables/Modals";
import Popup from "../client/reusables/Popup";
import { ENDPOINTS } from "../utils/Endpoints";


function AddMusic() {

    let [imgPath, setImgPath] = useState("");
    let [artwork, setArtwork] = useState("");
    let [musicUrls, setMusicUrls] = useState({
        youTube: "",
        appleMusic: "",
        spotify: "",
        soundCloud: ""
    });
    let [isLoading, setIsLoading] = useState(false);
    let [popupContent, setPopupContent] = useState(<Loader />);

    const urlFields = [
        { title: "YouTube Url", desc: "Enter YouTube link", value: "youTube" },
        { title: "Apple Music Url", desc: "Enter Apple music link", value: "appleMusic" },
        { title: "Spotify Url", desc: "Enter Spotify link", value: "spotify" },
        { title: "Soundcloud Url", desc: "Enter Soundcloud link", value: "soundCloud" },
    ]

    const pickFile = (e) => {
        const fileUpload = document.getElementById("file-upload");
        // const fileUploadBtn = document.getElementById("file-upload-btn");
        e.preventDefault();
        fileUpload.click();
    }

    const uploadFile = (e) => {
        const musicArt = document.getElementById("music-artwork");

        setImgPath(e.target.value);
        setArtwork(e.target.files[0]);
        musicArt.src = URL.createObjectURL(e.target.files[0]);
    }

    const submit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const form = new FormData();
        form.append("artwork", artwork);
        form.append("youtube", musicUrls.youTube);
        form.append("apple_music", musicUrls.appleMusic);
        form.append("spotify", musicUrls.spotify);
        form.append("soundcloud", musicUrls.soundCloud);

        const url = ENDPOINTS.addMusic;
        MakePostRequest(form, url, (res, err) => {
            if (err) {
                setPopupContent(
                    <ErrorModal
                        title={"An error occurred"}
                        desc={err.message}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                );
            }
            if (res.status === true) {
                setPopupContent(
                    <SuccessModal
                        title={"Success"}
                        desc={res.msg}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                )
                console.log("Success", res.msg);
            } else {
                setPopupContent(
                    <ErrorModal
                        title={"An error occurred"}
                        desc={res.msg}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                );
            }
        });
    }
    return (
        <div>
            <div className="boxed">
                <h2>Add Music</h2>
            </div>
            <div className="boxed flex">
                <div className="flex-2">
                    <form onSubmit={submit}>
                        <div className="boxed">
                            <label>Add artwork</label>
                            <div style={{
                                border: "1px solid #c0c0c0",
                                height: "200px",
                                width: "300px",
                                borderRadius: "8px",
                                overflow: "hidden"
                            }} className="mt-16 center">
                                <img id="music-artwork" alt="img" />
                            </div>
                            <div>{imgPath}</div>
                            <input type="file" id="file-upload" onChange={uploadFile} hidden />
                            <button className='alt-btn mt-8' id="file-upload-btn" onClick={pickFile}>Upload Image</button>
                        </div>
                        {
                            urlFields.map((each, i) => {
                                return (
                                    <div className="boxed" key={i}>
                                        <label>{each.title}</label>
                                        <input type="text" className="input mt-8" placeholder={each.desc} onChange={(e) => {
                                            setMusicUrls({ ...musicUrls, [each.value]: e.target.value });
                                        }} />
                                    </div>
                                )
                            })
                        }
                        <div className="boxed">
                            <button className="primary-btn">Publish</button>
                        </div>
                    </form>
                </div>
                <div className="flex-1"></div>
            </div>
            {isLoading && <Popup
                content={popupContent}
            />}
        </div>
    )
}

export default AddMusic;