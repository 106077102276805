import React from "react";
import { Routes, Route } from "react-router-dom";
import Blog from "./Blog";
import Home from "./Home";
import Nav from "./reusables/Nav";
import Shop from "./Shop";

function Client() {
    return (
        <div className="bg1">
            <Nav />
            <Routes>
                <Route exact path={"/"} element={<Home />} />
                <Route path={"/blog"} element={<Blog />} />
                <Route path={"/shop"} element={<Shop />} />
            </Routes>
        </div>
    )
}

export default Client;