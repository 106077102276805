import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import AddMusic from "./AddMusic";
import AddPost from "./AddPost";
import AddProducts from "./AddProducts";
import Music from "./Music";
import Posts from "./Posts";
import Products from "./Products";
import DashboardNav from "./shared/DashboardNav";
// import Home from "./Home";
import SideNav from "./shared/Sidenav";

function Dashboard() {

    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    window.addEventListener("resize", () => setIsMobile(window.innerWidth < 992));

    return (
        <div className="dashboard bg1">
            <div className={(isMobile ? "dashboard-sidenav-mobile" : "dashboard-sidenav")}>
                <SideNav />
            </div>
            <div className="dashboard-body">
                <DashboardNav />
                <Routes>
                    <Route exact path="/" element={<Posts />} />
                    <Route path="/add-post" element={<AddPost />} />
                    <Route path="/music" element={<Music />} />
                    <Route path="/add-music" element={<AddMusic />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/add-products" element={<AddProducts />} />
                </Routes>
            </div>
        </div>
    )
}

export default Dashboard;