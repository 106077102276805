import React, { useState } from "react";
import Logo from "../../client/reusables/Logo";
import { Link } from "react-router-dom";

function SideNav() {

    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [activeLink, setActiveLink] = useState("");

    window.addEventListener("resize", () => setIsMobile(window.innerWidth < 992));

    const closeSideNav = () => {
        isMobile && (() => {
            const sidebar = document.getElementsByClassName("dashboard-sidenav-mobile");
            sidebar[0].style.width = "0";
        })();
    }

    const links = [
        { name: "Posts", link: "/admin/dashboard/" },
        { name: "Music", link: "/admin/dashboard/music" },
        { name: "Products", link: "/admin/dashboard/products" }
    ];

    window.addEventListener("load", () => {
        const { pathname } = window.location;
        if (pathname.endsWith("/")) return setActiveLink("posts");
        if (pathname.endsWith("/music")) return setActiveLink("music");
        if (pathname.endsWith("/products")) return setActiveLink("products");
        if (pathname.endsWith("/add-products")) return setActiveLink("products");
    })

    return (
        <div style={{ position: "relative", overflowX: "hidden" }}>
            {isMobile &&
                <div style={{
                    position: "absolute",
                    top: "5%",
                    right: "5%"
                }}>
                    <i className="fa fa-times fs-32 alt-txt" onClick={closeSideNav}></i>
                </div>
            }
            <div className="">
                <Logo width={100} />
            </div>
            <div style={{ padding: "8px" }}>
                {
                    links.map((each, i) => {
                        return (
                            <Link key={i} to={each.link} onClick={() => setActiveLink(each.name.toLowerCase())}>
                                <div className={`mt-16 sidenav-link ${each.name.toLowerCase() === activeLink && 'active-sidenav'}`}>
                                    {each.name}
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default SideNav;