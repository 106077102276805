import React from "react";

function Socials() {

    const socialIcons = [
        { name: "facebook", icon: "fa fa-facebook", url: "https://facebook.com/doncrucifixtoent" },
        { name: "instagram", icon: "fa fa-instagram", url: "https://instagram.com/doncrucifixtoent" },
        { name: "twitter", icon: "fa fa-twitter", url: "https://twitter.com/doncrucifixto" },
    ]

    return (
        <div className="row v-center mt-16">
            {
                socialIcons.map((each, i) => {
                    return (
                        <a key={i} target="_blank" without="true" rel="noreferrer" href={each.url}>
                            <i className={`${each.icon} fs-24 alt-txt`} style={{
                                marginRight: "24px",
                                marginTop: "8px"
                            }}></i>
                        </a>
                    )
                })
            }
        </div>
    )
}

export default Socials;