import React, { useState } from "react";

function Gallery(props) {

    let [pos, setPos] = useState(0);

    const swipeRight = () => {
        const galleryImg = document.getElementsByClassName("gallery-img");
        if (pos >= props.images.length - 1) {
            return setPos(0);
        }
        setPos(pos + 1);
        galleryImg[0].className = "gallery-img move-right";
        setTimeout(() => {
            galleryImg[0].className = "gallery-img";
        }, 500);
    }

    const swipeLeft = () => {
        const galleryImg = document.getElementsByClassName("gallery-img");
        if (pos === 0) {
            return setPos(props.images.length - 1);
        }
        setPos(pos - 1);
        galleryImg[0].className = "gallery-img move-left";
        setTimeout(() => {
            galleryImg[0].className = "gallery-img";
        }, 500);
    }

    return (
        <div style={{
            position: "fixed",
            zIndex: 2,
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(0, 0, 0, 0.9)",
            overFlow: "hidden"
        }}>
            <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "2em",
                overflow: "hidden",
                positon: "relative",
                // backgroundColor: "red"
            }}>
                <span className="fa fa-chevron-left gallery-left-btn" onClick={swipeLeft}></span>
                <span className="fa fa-chevron-right gallery-right-btn" onClick={swipeRight}></span>
                <span className="fa fa-times close-gallery" onClick={props.close}></span>
                <img src={props.images[pos]} alt="img" className="gallery-img" />

            </div>
        </div>
    )
}

export default Gallery;