import React, { useState } from 'react';
import Loader from "../client/reusables/Loader";
import { MakePostRequest } from '../client/reusables/MakeRequest';
import { ErrorModal, SuccessModal } from "../client/reusables/Modals";
import Popup from "../client/reusables/Popup";
import { ENDPOINTS } from '../utils/Endpoints';

function AddPost() {

    let [filePath, setFilePath] = useState("");
    let [url, setUrl] = useState("");
    let [title, setTitle] = useState("");
    let [picture, setPicture] = useState("");
    let [isLoading, setIsLoading] = useState(false);
    let [popupContent, setPopupContent] = useState(<Loader />);

    const pickFile = (e) => {
        const featuredImg = document.getElementById("post-featured-img");
        setPicture(e.target.files[0]);
        featuredImg.src = URL.createObjectURL(e.target.files[0]);
        setFilePath(e.target.value);
    }

    const uploadFile = (e) => {
        const uploadInput = document.getElementById("upload-input");
        e.preventDefault();
        uploadInput.click();
    }

    const publish = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let form = new FormData();
        form.append("title", title);
        form.append("url", url);
        form.append("file_to_upload", picture);

        const endpoint = ENDPOINTS.addPost;
        MakePostRequest(form, endpoint, (res, err) => {
            if (err) {
                setPopupContent(
                    <ErrorModal
                        title={"An error occurred"}
                        desc={err.message}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                );
            }
            if (res.status === true) {
                setPopupContent(
                    <SuccessModal
                        title={"Success"}
                        desc={res.msg}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                )
                console.log("Success", res.msg);
            } else {
                setPopupContent(
                    <ErrorModal
                        title={"An error occurred"}
                        desc={res.msg}
                        btnAction={() => {
                            setIsLoading(false);
                            setPopupContent(<Loader />);
                        }}
                    />
                );
            }
        });
    }


    return (
        <div>
            <div className="boxed">
                <h2>Add a new post</h2>
            </div>
            <form onSubmit={publish}>
                <div className="boxed mt-16 flex">
                    <div className='flex-2'>

                        <div className="boxed">
                            <label>Title</label>
                            <input className='input mt-8' type="text" placeholder="Enter a title" onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="boxed">
                            <label>Link</label>
                            <input className='input mt-8' type="text" placeholder="Enter post url" onChange={(e) => setUrl(e.target.value)} />
                        </div>
                        <div className='boxed'>
                            <label>Add featured image</label>
                            <div style={{
                                border: "1px solid #c0c0c0",
                                height: "200px",
                                width: "300px",
                                borderRadius: "8px",
                                overflow: "hidden"
                            }} className="mt-16 center">
                                <img id="post-featured-img" alt="img" />
                            </div>
                            <div className='mt-8' style={{ whiteSpace: "pre-line" }}>{filePath}</div>
                            <input type="file" id="upload-input" accept="image/*" onChange={pickFile} hidden />
                            <button className='alt-btn mt-8' id="upload-btn" onClick={uploadFile}>Upload Image</button>
                        </div>
                        <div className="boxed">
                            <button className='primary-btn'>Publish</button>
                        </div>
                    </div>
                    <div className="flex-1"></div>
                </div>
            </form>
            {isLoading && <Popup
                content={popupContent}
            />}
        </div>
    );
}

export default AddPost;