import React from "react";

function Popup(props) {
    return (
        <div className="popup-div">
            <div className="boxed">
                {props.content}
            </div>
        </div>
    )
}

export default Popup;