import React from "react";

export function ButtonWithArrow(props) {
    return (
        <button className="primary-btn" onClick={props.clicked}>
            <div className="row v-center">
                <div>{props.btnText}</div>
                <div style={{ marginLeft: 8, marginTop: 2 }}>
                    <i className="fa fa-arrow-right"></i>
                </div>
            </div>
        </button>
    );
}