import React, { useState } from "react";

function DashboardNav() {

    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    window.addEventListener("resize", () => setIsMobile(window.innerWidth < 992));

    const openSideNav = () => {
        isMobile && (() => {
            const sidebar = document.getElementsByClassName("dashboard-sidenav-mobile");
            sidebar[0].style.width = "100%";
        })();
    }

    return (
        <div>
            <div className="row v-center boxed">
                {isMobile &&
                    <div className="flex-1">
                        <i className="fa fa-bars alt-txt fs-24" onClick={openSideNav}></i>
                    </div>
                }

            </div>
        </div>
    )
}

export default DashboardNav;