import React from 'react';

function BlogPostFormat(props) {
    const d = new Date(props.postDate);
    const month = d.getMonth();
    const date = d.getDate();
    const year = d.getFullYear();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]

    return (
        <div style={{
            backgroundImage: `url('${props.imgUrl}')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

        }} className="blog-post-format">
            <div className="overlay">
                <div>
                    <h2>{props.postTitle}</h2>
                    <div className='row v-center mt-16'>
                        <i className='fa fa-clock clock-icon'></i>
                        <div>{months[month]} {date}, {year}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPostFormat;