import React from "react";
import { UPLOADS_PATH } from "../../utils/variables";

function Logo(props) {
    return (
        <div>
            <img src={`${UPLOADS_PATH}/logo2.png`} alt="img" width={props.width || 200} height={props.height || "auto"} />
        </div>
    )
}

export default Logo;