import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Thanks() {

    let [num, setNum] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        setInterval(() => {

            if (num === 0) {
                // clearInterval(t);
                setNum(0);
                return navigate("/");
            }
            setNum(num - 1);
            // console.log(num)
        }, 1000);



    });

    return (
        <div className="thanks-page boxed">
            <div className="alt-txt fs-32 fw-700 center">Thank you!</div>
            <div className="mt-16 fs-24 center">Our team will be in touch with you shortly.</div>
            <div className="boxed mt-16">
                <div className="center">Redirecting you back to the home page in</div>
                <div className="fs-64 alt-txt fw-700 center mt-16">{num}</div>
            </div>
        </div>
    )
}

export default Thanks;