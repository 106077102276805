import React, { useState } from "react";
import { UPLOADS_PATH } from "../../utils/variables";
import { Link } from "react-router-dom";

function Nav() {

    let [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    let [isOpen, setIsOpen] = useState(false);
    let [activeLink, setActiveLink] = useState("home");



    window.addEventListener("resize", () => {
        if (window.location.pathname === "/") setIsMobile(window.innerWidth < 992);
    });

    window.addEventListener("load", () => {
        if (window.location.pathname.endsWith("/")) return setActiveLink("home");
        if (window.location.pathname.endsWith("/shop")) return setActiveLink("shop");
        if (window.location.pathname.endsWith("/blog")) return setActiveLink("blog");
    })

    window.onscroll = () => {
        if (window.location.pathname === "/") {
            const home = document.getElementById("home");
            const about = document.getElementById("about");
            const services = document.getElementById("services");
            const contact = document.getElementById("contact");
            const nav = document.getElementsByClassName("nav");
            const navHeight = nav[0].clientHeight;


            const homePos = home.offsetTop;
            const aboutPos = about.offsetTop;
            const servicesPos = services.offsetTop;
            const contactPos = contact.offsetTop;

            if ((window.scrollY + navHeight) >= navHeight) {
                nav[0].style.backgroundColor = "rgb(0, 0, 0, 0.9)";
                nav[0].style.borderBottom = "1px solid #DCA744";
            }
            if (window.scrollY < navHeight) {
                nav[0].style.background = "transparent";
                nav[0].style.borderBottom = "none";
            }
            if ((window.scrollY + navHeight) >= homePos && window.scrollY < aboutPos) {
                setActiveLink("home");
            }
            if ((window.scrollY + navHeight) >= aboutPos && window.scrollY < servicesPos) {
                setActiveLink("about us");
            }
            if ((window.scrollY + navHeight) >= servicesPos) {
                setActiveLink("our services");
            }
            if ((window.scrollY + navHeight) >= contactPos) {
                setActiveLink("contact us");
            }
        }

    }

    const links = [
        { name: "Home", route: "/" },
        { name: "About Us", route: "/" },
        { name: "Our Services", route: "/" },
        { name: "Contact Us", route: "/" },
        { name: "Shop", route: "/shop" },
        { name: "Blog", route: "/blog" },
    ];

    const navLinks = links.map((each, i) => {
        return <Link to={each.route} key={i} onClick={(e) => {
            const home = document.getElementById("home");
            const about = document.getElementById("about");
            const services = document.getElementById("services");
            const contact = document.getElementById("contact");
            const nav = document.getElementsByClassName("nav");
            const navHeight = nav[0].clientHeight;

            const homePos = home.offsetTop;
            const aboutPos = about.offsetTop;
            const servicesPos = services.offsetTop;
            const contactPos = contact.offsetTop;

            // const bg1 = document.getElementsByClassName("bg1");
            // const page = document.getElementById("page");


            setActiveLink(each.name.toLowerCase());
            isMobile && closeSideBar();
            switch (each.name.toLowerCase()) {
                case "home":
                    e.preventDefault();
                    // console.log(page.scrollX, page.scrollY);
                    window.scrollTo(0, homePos);

                    break;
                case "about us":
                    e.preventDefault();
                    window.scrollTo(0, aboutPos - navHeight);
                    break;
                case "our services":
                    e.preventDefault();
                    window.scrollTo(0, servicesPos - navHeight);
                    break;
                case "contact us":
                    e.preventDefault();
                    window.scrollTo(0, contactPos - navHeight);
                    break;
                case "shop":
                    return window.location.href = "https://doncrucifixtoshop.com/";
                default:
                //do nothing.
            }

        }}>
            <div className={activeLink === each.name.toLowerCase() ? "m-16 p-8 active-navlink fs-14" : "flex-1 m-16 p-8 navlink fs-14"}>{each.name}</div>
        </Link>
    });

    function openSideBar() {
        let closeBtn = document.getElementsByClassName("fa-times");
        setIsOpen(true);
        setTimeout(() => {
            closeBtn[0].className = "fa fa-times"
        }, 1200);
    }

    function closeSideBar() {
        let closeBtn = document.getElementsByClassName("fa-times");
        setIsOpen(false);
        closeBtn[0].className = "fa fa-times rotate"
    }

    return (
        <div className="row v-center p-16 nav">
            <div className="flex-1 p-8">
                <img src={`${UPLOADS_PATH}/nav-logo.png`} width={isMobile ? 80 : 100} height={"auto"} alt={"img"} />
            </div>
            <div className="flex-3 p-8 row flex-end">
                <div className="flex">
                    {!isMobile && navLinks}
                    {
                        isMobile &&
                        <div>
                            <div>
                                <i className="fa fa-bars alt-txt" style={{ fontSize: "2em" }} onClick={() => openSideBar()}></i>
                            </div>
                            <div style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                zIndex: 1,
                                overflowX: "hidden",
                                backgroundColor: "#DCA744",
                                height: "100%",
                                width: isOpen ? "100%" : 0,
                                transition: "0.3s"
                            }}>
                                <div style={{
                                    height: "100%",
                                    width: isOpen ? "98%" : 0,
                                    padding: 16,
                                    backgroundColor: "#121212",
                                    overflowX: "hidden",
                                    transition: "0.8s",
                                    position: "relative"
                                }}>
                                    <i className="fa fa-times rotate"
                                        onClick={() => closeSideBar()}
                                        style={{
                                            position: "absolute",
                                            top: 16,
                                            right: 16,
                                            color: "#DCA744",
                                            fontSize: "2em",
                                        }}
                                    ></i>
                                    <div className="p-8" style={{ marginTop: "15%" }}>
                                        {navLinks}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Nav;