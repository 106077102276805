import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getProducts } from "../client/reusables/GetProducts";
import Loader from "../client/reusables/Loader";

function Products() {

    let [content, setContent] = useState(<div className="row v-center h-center boxed"><Loader /></div>);
    let [showPopup, setShowPopup] = useState(false);
    let [popupContent, setPopupContent] = useState(<Loader />);

    useEffect(() => {
        getProducts((res, err) => {
            if (err) return console.log("Failed to fetch products", err.message);
            if (res.status === true) {
                //display products.
                console.log(res);
            } else {
                //show empty products design.
                setContent(
                    <div className="center">
                        <h2>{res.msg}</h2>
                    </div>
                )
                console.log(res);
            }
        })
    }, []);

    return (
        <div className="boxed">
            <div className="row v-center">
                <div className="flex-1">
                    <h2>Products</h2>
                </div>
                <div className="flex-1 flex flex-end">
                    <Link to={"/admin/dashboard/add-products"}><button className="primary-btn">Add New</button></Link>
                </div>
            </div>
            <div className="mt-16 boxed">
                {content}
            </div>
        </div>
    )
}

export default Products;